<template>
  <v-btn v-bind="$attrs" v-on="$listeners" depressed rounded color="grey lighten-2">
    <slot name="icon"></slot>
    <slot></slot>
  </v-btn>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description IdButton Component Logic
   * @date 28.07.2020
   */
  export default {
    inheritAttrs: false,
    name: "IdButton",
    props: {
      icon: {
        type: String,
        required: false
      }
    }
  };
</script>

<style scoped></style>
